import React, { useEffect, useRef, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ShopCard from "./ShopCard";
import SwipeIndicator from "./SwipeIndicator";

export default function ProductsSlider({ data }) {
  return (
    <section className="featured-product-slider">
      <div className="featured-product-slider__wrapper clearfix">
        <h3 className="heading main-heading heading--large">Latest Releases</h3>
        <div className="slider-wrapper">
          <SwipeIndicator />
          <Swiper
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              550: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              800: {
                slidesPerView: 3.5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 4.5,
                spaceBetween: 20,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            modules={[Autoplay]}
            className="featured-product-slider__wrapper__feed"
          >
            {data.map((product) => (
              <SwiperSlide>
                <ShopCard
                  product={product.chooseProduct}
                  dontScale={product.dontScaleDownTheImage}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {console.log(data)}
      </div>
    </section>
  );
}
