import React, { useRef, useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

export default function MailchimpForm() {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();

  const [message, setMessage] = useState("");
  const inputRef = useRef();
  const nameRef = useRef();
  const surnameRef = useRef();
  const thankYouTextRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(email, {
      FNAME: name,
      LNAME: surname,
    }) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data.result, data.msg);
        if (data.result === "success") {
          inputRef.current.value = "";
          setMessage(data.msg);

          setTimeout(() => {
            setMessage("");
          }, 10000);
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
        setMessage(
          "Whoops! Something went wrong. Please try again. If the problem persists please email us at info@harrogatebrewery.co.uk"
        );

        setTimeout(() => {
          setMessage("");
        }, 10000);
      });
  };

  return (
    <form
      id="mailchimp"
      className="newsletter-form"
      onSubmit={(e) => handleSubmit(e)}
    >
      <input
        ref={nameRef}
        type="text"
        placeholder="Name"
        onKeyUp={(e) => setName(e.target.value)}
      />
      <input
        ref={surnameRef}
        type="text"
        placeholder="Surname"
        onKeyUp={(e) => setSurname(e.target.value)}
      />
      <input
        ref={inputRef}
        type="text"
        placeholder="Email address"
        onKeyUp={(e) => setEmail(e.target.value)}
      />
      <input type="submit" value="Sign up now" className="btn btn--black" />
      <div className="thank-you-text" ref={thankYouTextRef}>
        {message}
      </div>
    </form>
  );
}
