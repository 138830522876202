import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

export default function PostCard({ postData }) {
  return (
    <article className="articles__card" role="article">
      <GatsbyImage
        image={
          postData.acfCustomThumbnail.customThumbnail.localFile.childImageSharp
            .gatsbyImageData
        }
        alt={postData.acfCustomThumbnail.customThumbnail.altText}
      />
      <h2 className="articles__card__heading heading heading--medium">
        {postData.title}
      </h2>
      <div className="articles__card__text text text--small">
        {parse(postData.excerpt)}
      </div>
      <Link to={postData.uri} className="btn btn--black">
        Read more
      </Link>
    </article>
  );
}
