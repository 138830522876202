import React from "react";
import { graphql } from "gatsby";
import AboutSection from "../components/home/AboutSection";
import CallToAction from "../components/reusable/CallToAction";
// import InstagramFeed from "../components/reusable/InstagramFeed";
import ProductsSlider from "../components/reusable/ProductsSlider";
import HomeHeroSlider from "../components/reusable/HomeHeroSlider";
import LatestNews from "../components/home/LatestNews";
import Seo from "../components/global/Seo";

export default function Homepage({ data }) {
  const page = data.wpPage;
  return (
    <>
      <Seo seo={page.seo} />
      <HomeHeroSlider data={page.acfHomepage.featuredSlider} />
      <ProductsSlider data={page.acfHomepage.productSlider} />
      <AboutSection />
      <CallToAction
        backgroundColor={page.acfHomepage.callToAction1.ctaBackgroundColor}
        image={
          page.acfHomepage.callToAction1.ctaBackgroundImage.localFile
            .childImageSharp.gatsbyImageData
        }
        buttonLink={page.acfHomepage?.callToAction1?.ctaButton?.buttonLink?.url}
        buttonColor={page.acfHomepage.callToAction1.ctaButton.buttonColor}
        customTextRolloverColor={
          page.acfHomepage.callToAction1.ctaButton.customTextRolloverColor
        }
        buttonText={page.acfHomepage.callToAction1.ctaButton.buttonText}
        headline={page.acfHomepage.callToAction1.ctaHeadline}
        text={page.acfHomepage.callToAction1.ctaText}
      />
      <LatestNews />
      <CallToAction
        backgroundColor={page.acfHomepage.callToAction2.ctaBackgroundColor}
        image={
          page.acfHomepage.callToAction2.ctaBackgroundImage.localFile
            .childImageSharp.gatsbyImageData
        }
        buttonLink={page.acfHomepage?.callToAction2?.ctaButton?.buttonLink?.url}
        buttonColor={page.acfHomepage.callToAction2.ctaButton.buttonColor}
        customTextRolloverColor={
          page.acfHomepage.callToAction2.ctaButton.customTextRolloverColor
        }
        buttonText={page.acfHomepage.callToAction2.ctaButton.buttonText}
        headline={page.acfHomepage.callToAction2.ctaHeadline}
        text={page.acfHomepage.callToAction2.ctaText}
      />
      {/* <InstagramFeed /> */}
      <CallToAction
        backgroundColor={page.acfHomepage.callToAction3.ctaBackgroundColor}
        image={
          page.acfHomepage.callToAction3.ctaBackgroundImage.localFile
            .childImageSharp.gatsbyImageData
        }
        headline={page.acfHomepage.callToAction3.ctaHeadline}
        text={page.acfHomepage.callToAction3.ctaText}
        showMailchimpForm={page.acfHomepage.callToAction3.showMailchimpForm}
      />
    </>
  );
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 103 }) {
      id
      uri
      title
      seo {
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
      acfHomepage {
        featuredSlider {
          slideHeading
          slideLabel
          slideText
          imageBackground {
            backgroundColour1
            backgroundColour2
            imageType
            backgroundImage {
              imageOpacity
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            foregroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          slideButton {
            buttonText
            buttonLink {
              ... on WpPost {
                uri
              }
              ... on WpPage {
                uri
              }
              ... on WpProduct {
                uri
              }
            }
          }
        }
        productSlider {
          dontScaleDownTheImage
          chooseProduct {
            ... on WpProduct {
              id
              databaseId
              title
              uri
              dontBuildPage {
                dontBuildAPageForThisProduct
              }
              acf_shopify {
                shopifyProductId
              }
              acfBeerDrinkInfo {
                inStock
                longDescription
                biabPurchaseOptions {
                  optionPrice
                  quantityInStock
                  chooseProduct {
                    ... on WpProduct {
                      title
                    }
                  }
                }
              }
              acfDrinkInfo {
                inStock
                shortDescription
                abv
                colour
                colourDark
                productPurchaseOptions {
                  optionQuantity
                  optionPrice
                  optionNameCustom
                  optionName
                }
              }
              isGiftCard {
                isThisProductAGiftCard
              }
              acfCustomThumbnail {
                customThumbnail {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        callToAction1 {
          ctaBackgroundColor
          ctaHeadline
          ctaText
          ctaButton {
            buttonColor
            buttonText
            customTextRolloverColor
            buttonLink {
              url
            }
          }
          ctaBackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        callToAction2 {
          ctaBackgroundColor
          ctaHeadline
          ctaText
          ctaButton {
            buttonColor
            buttonText
            customTextRolloverColor
            buttonLink {
              url
            }
          }
          ctaBackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        callToAction3 {
          ctaBackgroundColor
          ctaHeadline
          ctaText
          showMailchimpForm
          ctaBackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
