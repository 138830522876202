import React from 'react'
import {StaticQuery, graphql } from 'gatsby'
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import PostCard from './PostCard';
import SwipeIndicator from '../reusable/SwipeIndicator';

export default function LatestNews({data}) {
  return (
    <StaticQuery
      query={graphql`
        query LatestNewsQuery {
          allWpPost(limit: 9) {
            nodes {
              title
              uri
              excerpt
              acfCustomThumbnail {
                customThumbnail {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section className="articles" role="region">
          <div className="articles_inner">
            <div className="wrapper">
              <h3 className='heading--small heading '>Latest News</h3>
              <div className="slider-wrapper">
                <SwipeIndicator />
                <Swiper
                // slidesPerView={3}
                // spaceBetween={20}
                breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                550: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                }}
                modules={[Autoplay]}
                >
                  {
                    data.allWpPost.nodes.map(post => <SwiperSlide><PostCard postData={post} /></SwiperSlide>)
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      )}
    />
  )
}
