import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../global/Button'
import parse from 'html-react-parser'
import MailchimpForm from './MailchimpForm'

export default function CallToAction({
  backgroundColor,
  image,
  buttonLink,
  buttonColor,
  customTextRolloverColor,
  buttonText,
  headline,
  text,
  showMailchimpForm
}
) {
  return (
    <section className={`call_to_action ${backgroundColor} ${image ? '' : 'no-img'}`}>
      <div className="call_to_action__wrapper wrapper--x-large no-padding clearfix">
        <GatsbyImage className="call_to_action__image-wrap" image={image} alt={'Harrogate Brewing Co. Circle Logo'} />
        <article className="call_to_action__text-wrapper">
          <h2 className="call_to_action__heading heading heading--x-large">{parse(headline)}</h2>
          <div className="call_to_action__text text text--small">{parse(text)}</div>
          {
            buttonLink !== undefined ? 
              <Button
              link={buttonLink}
              classes={''}
              color={buttonColor}
              rolloverTextColor={customTextRolloverColor}
              text={buttonText}
              />
            : ""
          }
          { showMailchimpForm !== undefined ? 
            <MailchimpForm/> 
            : ""
          }
        </article>
      </div>
    </section>
  )
}
