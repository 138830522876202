import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const BackgroundColor = styled.div`
  background: radial-gradient(${(props) => props.bg1}, ${(props) => props.bg2});
`;

const BackgroundImageWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  mix-blend-mode: difference;
  opacity: ${(props) => props.opacity / 100} !important;

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
  }
`;

export default function FeaturedSliderSlide({ slide }) {
  return (
    <BackgroundColor
      bg1={slide.imageBackground.backgroundColour1}
      bg2={slide.imageBackground.backgroundColour2}
      className="featured-slider--slide"
    >
      <div className="wrapper wrapper-flex clearfix">
        <article className="homeHero-slider__info">
          <label className="homeHero-slider__info__label animate">
            {slide.slideLabel}
          </label>
          <h2 className="homeHero-slider__info__heading heading heading--x-large">
            {slide.slideHeading}
          </h2>
          <div className="homeHero-slider__info__text text text--small animate">
            {slide.slideText}
          </div>
          <Link
            className="btn btn--white"
            to={slide?.slideButton?.buttonLink?.uri}
          >
            {slide.slideButton.buttonText}
          </Link>
        </article>
      </div>
      {slide.imageBackground.imageType ===
        "Image Foreground with Image Background" ||
      slide.imageBackground.imageType ===
        "Image Foreground with Colour Background" ? (
        <div className="homeHero-slider__image-wrap">
          <GatsbyImage
            className="foreground-image"
            image={
              slide.imageBackground.foregroundImage.localFile.childImageSharp
                .gatsbyImageData
            }
          />
        </div>
      ) : (
        ""
      )}

      {console.log(slide.imageBackground.backgroundImage)}
      {slide.imageBackground.imageType ===
        "Image Foreground with Image Background" ||
      slide.imageBackground.imageType === "Background Cover Image" ? (
        <BackgroundImageWrapper
          className="background-image-wrapper"
          opacity={slide.imageBackground.backgroundImage.imageOpacity}
        >
          <GatsbyImage
            className="background-image"
            image={
              slide.imageBackground.backgroundImage.image.localFile
                .childImageSharp.gatsbyImageData
            }
          />
        </BackgroundImageWrapper>
      ) : (
        ""
      )}
    </BackgroundColor>
  );
}
