import React from 'react'
import {StaticQuery, graphql } from 'gatsby'
import Logo from '../../images/logo_circle_black.svg'
import parse from 'html-react-parser'
import Button from '../global/Button'
import LogoRight from '../../images/hb-right.inline.svg'
import LogoLeft from '../../images/hb-left.inline.svg'

export default function AboutSection({data}) {
  return (
    <StaticQuery
      query={graphql`
        query AboutSection {
          wpPage(title: {}, databaseId: {eq: 103}) {
            acfHomepage {
              aboutIntroHeadline
              aboutIntroText
              aboutIntroButton {
                buttonLink {
                  url
                }
                buttonText
                customTextRolloverColor
                buttonColor
              }
            }
          }
        }
      `}
      render={data => (
        <section className="about" role="region">
          <img className="about-logo" src={Logo} alt="Harrogate Brewing Co. Circle Logo" />
          <h2 className="heading heading--x-large">{data.wpPage.acfHomepage.aboutIntroHeadline}</h2>
          <div className="text">{parse(data.wpPage.acfHomepage.aboutIntroText)}</div>
          <Button
          link={data.wpPage?.acfHomepage?.aboutIntroButton?.buttonLink?.url}
          classes={''}
          color={data.wpPage.acfHomepage.aboutIntroButton.buttonColor}
          rolloverTextColor={data.wpPage.acfHomepage.aboutIntroButton.customTextRolloverColor}
          text={data.wpPage.acfHomepage.aboutIntroButton.buttonText}
          />
          <LogoLeft className="logoLeft" />
          <LogoRight className="logoRight" />
        </section>  
      )}
    />
  )
}
