import React, { useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import ShopCard from "./ShopCard";
import HomeHeroSliderSlide from "./HomeHeroSliderSlide";
import Hops from "../../images/pagination_hop.svg";

export default function InstagramFeed({ data }) {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className}"><img src="${Hops}" /></span>`;
    },
  };
  return (
    <section className="homeHero-slider" role="slider">
      <div className="homeHero-slider--inner">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          effect={"fade"}
          pagination={pagination}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          modules={[Autoplay, EffectFade, Pagination]}
          className="featured-product-slider__wrapper__feed"
        >
          {data.map((slide) => (
            <SwiperSlide>
              <HomeHeroSliderSlide slide={slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
